<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				input: {},
			}
		},
		computed:{
			mrValidation(){
				return this.$root.config.mrValidation.forgot_password
			},
			uCode() {
				return this.$route.params.code
			}
		},
		async mounted() {
			await this.ready();
			setTimeout(() => { 
				SEMICOLON.header.init();
				SEMICOLON.widget.extras(); }, 300)
			this.$set(this.$root, 'page', this);
		},
		methods: {
			onSubmit(e) {
				if (e && e.btnLoading()) return;
				var input = this.input
				input.code = this.uCode
				Gen.apirest('/change-password', this.input, (err, resp) => {
					if (e) e.btnUnloading()
					if (err) return this.$refs.info.show(err.responseJSON.message, "warning")
					// this.$refs.info.show(resp.message, "success")
					swal(resp.message, "", "success")
					this.$router.push({
						name: "Login"
					})
				}, 'POST')
			},
		},
	};
</script>
<template>
	<section id="content" style="background-color: #000; overflow: visible">
		<div class="content-wrap pt-0 pb-0">
			<div id="section-about" class="section page-section bg_0f181b nobottommargin notopmargin clearfix">
				<div class="container clearfix">
					<div class="row  justify-content-center dark">
						<div class="col-md-6 col-sm-6 col-lg-5">
							<div class="wrap_login">
								<h2>{{web.lbl_forgot_password}}</h2>
								<h4>{{web.lbl_enter_your_password}}</h4>
								<VForm @resp="onSubmit">
									<VInfo ref="info"></VInfo>
									<div class="row">
										<div class="col-md-12 mb-2">
											<div class="form-group">
												<input type="password" name="password" id="password"
													v-model="input.password" v-bind="validation('password')"
													class="frm_custom_sign padleft_form" placeholder="Password">
												<LabelError name="password"></LabelError>
											</div>
											<div class="form-group">
												<input type="password" name="confirm_password" v-model="input.confirm_password" v-bind="validation('confirm_password')" class="frm_custom_sign padleft_form" placeholder="Confirm Password">
											<LabelError name="confirm_password"></LabelError>
											</div>
										</div>
										<div class="col-md-12 text-center">
											<p><button type="submit" class="btn_line w_100 btn-loading">{{web.lbl_forgot_password}}
													<i class="icon-line-arrow-right"></i></button></p>
										</div>
										<div class="col-md-12">
											<div class="divider divider-short  mb-3 divider-center">{{web.lbl_or}}
											</div>
										</div>
										<div class="col-md-12">
											<p class="text-center">
												<router-link :to="{name:'Login'}" class="borbot">
													{{web.lbl_login}}
												</router-link>
											</p>
										</div>
									</div>
								</VForm>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="clear"></div>
		</div>
	</section>
</template>